<template>
  <b-modal
    visible
    scrollable
    centered
    :title="title"
    size="lg"
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <div class="">
      <div class="d-flex align-items-center mb-2">
        <b-form-input
          v-model="templateSearchString"
          type="text"
          debounce="500"
          placeholder="Поиск шаблона"
          size="sm"
          trim
          class="mr-2"
        />

        <b-button
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          size="sm"
          @click="openSmsTemplateEditModal"
        >
          Добавить
        </b-button>
      </div>

      <div
        v-if="filteredTemplates.length"
        style="max-height: 500px; overflow-y: auto;"
      >
        <div
          v-for="template in filteredTemplates"
          :key="template.id"
          class="template-item"
        >
          <div class="template-item__title d-flex align-items-center mb-2 bg-white">
            <div
              class="d-flex justify-content-between align-items-center bg-primary rounded p-2 w-100"
            >
              <div class="template-item__text">
                {{ template.text }}
              </div>
            </div>

            <b-button
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              title="Изменить"
              size="sm"
              class="ml-2 size-btns"
              @click="openSmsTemplateEditModal(template)"
            >
              <b-icon icon="pencil-square" />
            </b-button>
            <b-button
              variant="danger"
              :type="$const.PRIMARY_BUTTON"
              title="Удалить"
              size="sm"
              class="ml-2 size-btns"
              @click="openSmsTemplateDeleteModal(template.id)"
            >
              <b-icon icon="trash" />
            </b-button>
            <b-button
              variant="success"
              :type="$const.PRIMARY_BUTTON"
              title="Выбрать"
              size="sm"
              class="ml-2 mr-2 size-btns"
              :disabled="template.text === text"
              @click="setSmsText(template.text)"
            >
              <b-icon icon="check-circle" />
            </b-button>
          </div>
        </div>
      </div>

      <small
        v-if="templateSearchString && !filteredTemplates.length"
        class="ml-2"
      >
        Шаблон не найден
      </small>
    </div>

    <template #modal-footer>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        @click="onClose"
      >
        Закрыть
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';

export default {
  name: 'SmsTemplatesModal',
  components: {
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: 'Выберите шаблон',
    },
    agreedServices: {
      type: String,
      default: null,
    },
    allTemplates: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      templateSearchString: '',
      text: '',
      name: '',
      templates: this.allTemplates,
    };
  },

  computed: {
    filteredTemplates() {
      return this.templates.filter((t) => {
        if (this.templateSearchString && !t.text.toLowerCase().includes(this.templateSearchString.toLowerCase())) return false; // search by template from templatesSearchString

        return true;
      });
    },
    availableCharacters() {
      return Math.max(0, this.maxTextLength - this.text.length);
    },
    textError() {
      return this.errors.text ? this.errors.text[0] : null;
    },
  },
  methods: {
    onClose() {
      this.$emit('input', false);
    },
    async uploadSmsTemplates() {
      try {
        this.templates = await this.$store.dispatch(this.$types.SMS_TEMPLATES_FETCH);
      } catch (err) {
        console.log('err', err);
      }
    },
    openSmsTemplateDeleteModal(templateId) {
      uiService.showModal(MODALS.SMS_TEMPLATE_DELETE_MODAL, {
        name: 'SmsTemplateDeleteModal',
        props: { templateId },
        actions: {
          afterDelete: () => {
            this.uploadSmsTemplates();
          },
        },
      });
    },
    openSmsTemplateEditModal(template) {
      uiService.showModal(MODALS.SMS_TEMPLATE_EDIT_MODAL, {
        name: 'SmsTemplateEditModal',
        props: {
          template,
        },
        actions: {
          afterSaveFunc: () => {
            this.uploadSmsTemplates();
            this.templateSearchString = '';
          },
        },
      });
    },
    setSmsText(value) {
      this.$emit('selectTemplate', value);
      this.onClose();
    },
  },
};
</script>

<style lang="scss" scoped>
.template-item {
  position: relative;

  &:not(:first-child) {
    margin-top: 1.2rem;
  }

  &__title {
    position: sticky;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 10;

    &::before {
      position: absolute;
      content: '';
      transform: translate(0, -100%);
      left: 0;
      top: 0;
      height: 17px;
      width: 100%;
      background-color: #fff;
      z-index: -1;
    }
  }

  &__text {
    word-break: break-word;
    color: white;
    white-space: pre-wrap;
  }
}

.size-btns {
  width: 40px;
  height: 40px;
}
</style>
